<template>
    <DashboardWrapper>
        <Edit v-if="editModal" title="Lid aanpassen" text="Gegevens veranderen van [lid]" @close="this.editModal = false"/>
        <div class="container mx-auto">
            <h2 class="text-white pb-4 mb-4 mx-4 sm:mx-0 uppercase text-xl font-extrabold">Alle leden</h2>

            <div class="flex justify-end space-x-16">
                <router-link class="my-auto" :to="{ name: '' }">    
                    <div class="text-white">+ Leden uitnodigen</div>
                </router-link>
                <SearchInput placeholder="Lid zoeken" theme="dark"/>
            </div>

            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('name')">
                                                <div>Naam</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('email')">
                                                <div>Email</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('birthdate')">
                                                <div>Geboortedatum</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>
                                        
                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('team')">
                                                <div>Team</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('analyses')">
                                                <div>Analyses</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort('status')">
                                                <div>Aangemeld</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-500">
                                    <template v-if="leden.length !== 0">
                                        <tr v-for="lid in sortedLeden" :key="lid.name">
                                            <LidRow :lid="lid" @open="this.editModal = true" />
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td colspan="5" class="px-6 py-5 whitespace-nowrap text-sm font-medium text-center text-white">
                                            Er zijn momenteel geen leden
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import LidRow from "@/components/rows/LidRow";
import SearchInput from "@/components/forms/SearchInput";
import Edit from "@/components/modals/Edit";

export default {
    name: "leden",
    components: {
        DashboardWrapper,
        LidRow,
        SearchInput,
        Edit,
    },

    data() {
        return {
            // modalTitle: 'Lid verwijderen',
            // modalText: 'Weet je zeker dat je ... wilt verwijderen?',

            currentSort: 'name',
            currentSortDir: 'asc',

            // Testdata
            leden: [
                { name: 'Sebastiaan Hartman',   email:'SebasJHartman@gmail.com',    birthdate:'17-07-1999',     team: 'U22-1',      analyses: '3',      status: 'Aangemeld' },
                { name: 'Chris Visser',         email:'ChrisVisser@hotmail.com',    birthdate:'03-01-1999',     team: 'U22-1',      analyses: '0',      status: 'Aangemeld' },
                { name: 'Rodin Boonstra',       email:'RodinBoonstra@gmail.com',    birthdate:'22-11-2000',     team: 'U22-2',      analyses: '0',      status: 'In afwachting' },
                { name: 'Max Bodde',            email:'MaxBodde@gmail.com',         birthdate:'27-12-2002',     team: 'U18-1',      analyses: '1',      status: 'Aangemeld' },
                { name: 'Nienke Poelstra',      email:'NienkePoelstra@gmail.com',   birthdate:'15-03-2002',     team: 'U18-3',      analyses: '2',      status: 'In afwachting' },
                { name: 'Amin Samadi',          email:'AminSamadi@chello.nl',       birthdate:'11-05-2006',     team: 'U16-2',      analyses: '5',      status: 'In afwachting' },
            ],

            editModal: false,
            deleteModal: false,
            inviteModal: false,
        }
    },  

    methods: {
        sort:function(s) {
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
        }
    },

    computed: {
        sortedLeden:function(){
            return this.leden.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0
            }); 
        }
    }

}

</script>

<style>
/* 
    body {
        overflow-y: hidden; 
        overflow-x: hidden; 
}
*/
</style>