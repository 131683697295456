<template>
    <div>
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5 text-gray-900">{{ label }}</label>
        <div class="mt-1 max-w-lg rounded-md shadow-sm text-xs sm:text-sm">
            <select ref="input" name="" id="label" @change="updateValue()" class="block form-select w-full transition duration-150 ease-in-out text-xs sm:text-sm sm:leading-5">
                <option value="" disabled :selected="!value">Maak een keuze</option>
                <option v-for="option in options" :selected="option.key == value" :key="option.key" :value="option.key" class="text-xs sm:text-sm">{{ option.text }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectInput",
    props: ['value', 'label', 'password', 'placeholder', 'options'],
}
</script>

<style scoped>

</style>
